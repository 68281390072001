import * as React from 'react'
import {Toaster} from 'react-hot-toast'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {Hydrate} from 'react-query/hydration'

import {UserContextProvider} from '@/client/auth/context'
import {useSetAuthCookie} from '@/client/auth/utils/use-set-auth-cookie'

import {ThemeProvider} from './theme-context'

/**
 * A collection of providers for app and site
 */
function Providers({
  dehydratedState,
  children,
}: {
  dehydratedState: unknown
  children: React.ReactNode
}) {
  useSetAuthCookie()
  const queryClientRef = React.useRef<QueryClient>()
  if (!queryClientRef.current) {
    // LATER: configure options
    queryClientRef.current = new QueryClient()
  }
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClientRef.current}>
        <Hydrate state={dehydratedState}>
          <UserContextProvider>{children}</UserContextProvider>
          <Toaster />
          <ReactQueryDevtools position="bottom-right" />
        </Hydrate>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export {Providers}
