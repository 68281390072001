import c from 'camelcase'
import {reduce} from 'lodash'

const format = (data: Record<string, unknown>) =>
  reduce(data, (result, value, key) => ({...result, [c(key)]: value}), {})

function camelcase<T>(
  data: Record<string, unknown>,
  nestedKeys?: {key: string; type: 'array' | 'object'}[],
): T {
  return reduce(
    data,
    (result, value, key) => {
      const nestedKey = nestedKeys?.find(data => data.key === key)
      if (!nestedKey) {
        return {
          ...result,
          [c(key)]: value,
        }
      }
      if (nestedKey.type === 'object') {
        return {
          ...result,
          [c(key)]: format(value as Record<string, unknown>),
        }
      }
      return {
        ...result,
        [c(key)]: (value as Record<string, unknown>[]).map(format),
      }
    },
    {} as T,
  )
}

export {camelcase}
