import * as React from 'react'
import type {Session, User} from '@supabase/supabase-js'

import {supabase} from '../utils/supabase-client'

const UserContext = React.createContext<{
  session: Session | null
  user: User | null
  isLoading: boolean
}>({user: null, session: null, isLoading: true})

UserContext.displayName = 'UserContext'

function UserContextProvider(props: {children: React.ReactNode}) {
  const [session, setSession] = React.useState<Session | null>(null)
  const [user, setUser] = React.useState<User | null>(null)
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    const session = supabase.auth.session()
    console.log(`Session`, session)
    setSession(session)
    setUser(session?.user ?? null)
    setIsLoading(false)
    const {data: authListener} = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log(event, session)
        setSession(session)
        setUser(session?.user ?? null)
      },
    )
    return () => {
      authListener?.unsubscribe()
    }
  }, [])

  const value = {
    session,
    user,
    isLoading,
  }

  return <UserContext.Provider value={value} {...props} />
}

function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`)
  }
  return context
}

export {UserContextProvider, useUser}
