import {useRouter} from 'next/router'
import {validate} from 'uuid'

const addHyphens = (i: string) =>
  i.substr(0, 8) +
  '-' +
  i.substr(8, 4) +
  '-' +
  i.substr(12, 4) +
  '-' +
  i.substr(16, 4) +
  '-' +
  i.substr(20)

const removeHyphens = (i: string) => i.replace(/-/g, '')

/**
 * Turn into a format that our API client can understand
 */
function parseHandle(handleOrId: string) {
  const handle = validate(handleOrId) // Is given string a valid uuid?
    ? handleOrId // Then leave as is
    : validate(addHyphens(handleOrId)) // Is given string a stripped uuid?
    ? addHyphens(handleOrId) // Then turn it into a valid uuid
    : handleOrId // Otherwise this is an user-defined handle
  return handle
}

/*
 * Turn into a format that is exposed to users
 */
function slugifyHandle(handleOrId: string) {
  const slug = validate(handleOrId) // Is given string a valid uuid?
    ? removeHyphens(handleOrId) // Then remove hyphens
    : validate(addHyphens(handleOrId)) // Is given string a stripped uuid?
    ? handleOrId // Then leave as is
    : handleOrId // Otherwise this is an user-defined handle
  return slug
}

function useHandle(key: string) {
  const router = useRouter()
  const query = router.query[key]?.toString()
  return query && parseHandle(query)
}

export {addHyphens, parseHandle, slugifyHandle, useHandle}
