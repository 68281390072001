import type {PostgrestFilterBuilder} from '@supabase/postgrest-js'

import {camelcase} from './camelcase'
import {slugifyHandle} from './handle'

// ------------------------------
// Client
// ------------------------------

type FetchConfig = {
  [key in keyof RequestInit]: RequestInit[keyof RequestInit]
}

interface ClientConfig extends FetchConfig {
  data?: Record<string, unknown>
  token?: string
  headers?: Record<string, unknown>
}

async function client<T>(
  endpoint: string,
  {data, token, headers: customHeaders, ...customConfig}: ClientConfig = {},
) {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      ...(data && {'Content-Type': 'application/json'}),
      ...customHeaders,
    },
    ...customConfig,
  } as RequestInit

  return fetch(endpoint, config).then(async response => {
    let data = {}

    try {
      const text = await response.text()
      data = JSON.parse(text)
    } catch (err) {
      data = {
        status: response.status,
        message: response.statusText,
      }
    }

    if (response.ok) {
      return data as T
    } else {
      return Promise.reject(data)
    }
  })
}

// ------------------------------
// Supabase client
// ------------------------------

async function getStoredRecords<T extends Record<string, unknown>>(
  query: PostgrestFilterBuilder<T>,
) {
  const {data, error} = await query
  if (error) throw error
  if (!data || !data[0]) return null
  return data
}

async function getRecords<T extends Record<string, unknown>, U>(
  query: PostgrestFilterBuilder<T>,
  nestedKeys?: {key: string; type: 'array' | 'object'}[],
) {
  const {data, error} = await query
  if (error) throw error
  if (!data || !data[0]) return null

  return data.map(item => ({
    ...camelcase<U>(item, nestedKeys),
    slug: item.id ? slugifyHandle(item.id as string) : null,
  }))
}

async function getRecord<T extends Record<string, unknown>, U>(
  query: PostgrestFilterBuilder<T>,
  nestedKeys?: {key: string; type: 'array' | 'object'}[],
) {
  const {data, error} = await query.limit(1)
  if (error) throw error
  if (!data || !data[0]) return null
  const item = data[0]
  return {
    ...camelcase<U>(item, nestedKeys),
    slug: item.id ? slugifyHandle(item.id as string) : null,
  }
}

export {client, getRecord, getRecords, getStoredRecords}
