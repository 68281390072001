import * as React from 'react'

import {client} from '@/client/core/utils/api-client'

import {supabase} from './supabase-client'

/**
 * Send session to /api/auth route to set the auth cookie. This is only needed
 * for getServerSideProps i.e. supabase.auth.api.getUserByCookie(req)
 */
function useSetAuthCookie() {
  React.useEffect(() => {
    const session = supabase.auth.session()
    if (session?.user) {
      // Workaround to set auth cookies whenever we find an authenticated user
      client('/api/auth', {data: {event: 'SIGNED_IN', session}})
    }
    const {data: authListener} = supabase.auth.onAuthStateChange(
      (event, session) => {
        client('/api/auth', {data: {event, session}})
      },
    )
    return () => {
      authListener?.unsubscribe()
    }
  }, [])
}

export {useSetAuthCookie}
